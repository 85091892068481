<template>
  <article id="wrap">
    <form action="contact_confirm.html">
      <AppHeader theme="white">プライバシーポリシー</AppHeader>

      <section class="contents">
        <div class="contentInner pb_80">
          <div class="white_bg_area2">
            <div class="item">
              <div class="item_title">第１条（全般）</div>
              <ol>
                <li>
                  ステキナセカイ （以下「本サービス」といいます）を運営する株式会社ハイスピードボーイ（以下「当社」といいます）は、本サービスの利用者（以下「ユーザー」といいます）のプライバシーを尊重し、ユーザーの個人情報の管理に細心の注意を払うものとします。
                </li>
                <li>
                  当社は、責任を持ってユーザーの個人情報を保護するための方針を以下のとおり定め、施策の実施・維持、それらの継続的な改善を行います。当社は、個人情報保護に関する法令およびその他の規範を遵守します。
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第２条（個人情報）</div>
              <div>
                本プライバシーポリシーにおいて「個人情報」とは、個人情報保護法にいう、生存する個人に関する情報であって、当該情報に含まれる以下各号の情報その他の記述により特定の個人を識別することができるものをいいます。
              </div>
              <ol>
                <li>
                  ユーザーの氏名、住所、肖像、電話番号、メールアドレス、所属する会社等団体名、クレジットカード番号、運転免許証番号等その他特定の個人を識別することができる情報
                </li>
                <li>
                  端末情報（個体識別情報等を含む）
                </li>
                <li>
                  ログ情報及び行動履歴情報（IPアドレス、閲覧履歴、操作履歴情報を含む）
                </li>
                <li>
                  位置情報、移動情報
                </li>
                <li>
                  Cookie及び匿名ID
                </li>
                <li>
                  個人情報と一体となった個人の属性に関する情報
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第３条（個人情報の収集）</div>
              <ol>
                <li>
                  当社は、個人情報の入手を適法かつ公正な手段によって行うものとし、ユーザーの意思に反する不正な入手は行わないものとします。
                </li>
                <li>
                  当社は、個人情報を収集する場合、その利用目的をあらかじめ通知または公表するものとします。
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第４条（個人情報提供の任意性）</div>
              <div>ユーザーからの個人情報の提供は任意ですが、本サービスにおいてそれぞれ必要となる個人情報を開示頂けない場合は、本サービスを利用できない場合があります。</div>
            </div>

            <div class="item">
              <div class="item_title">第５条（個人情報の利用目的）</div>
              <div>
                当社は、ユーザーから収集した個人情報等を以下の目的のために使用するものとします。
              </div>
              <ol class="par">
                <li>本サービスの提供を行う場合</li>
                <li>
                  本サービスおよび関連するサービスに関する通知等を行う場合
                </li>
                <li>
                  ユーザーからのお問い合わせ・ご相談に対する回答・返答をする場合
                </li>
                <li>
                  ユーザーに対して本サービスに対する意見・感想等の提供を求める場合
                </li>
                <li>
                  本サービス内規約等に違反する行為またはその恐れのある行為を行ったユーザーに対して注意または警告を行う場合
                </li>
                <li>
                  当社に対する債務を有するユーザーに同債務の履行を要求する場合
                </li>
                <li>
                  本サービスに関連する外部サービスへの申請等
                </li>
                <li>
                  本サービスに関連するキャンペーン等の賞品発送
                </li>
                <li>
                  提携サービスの提供、ニーズや興味・関心に適合する広告情報等の表示、広告効果の分析
                </li>
                <li>
                  ターゲティング広告等の運用
                </li>
                <li>
                  統計データの作成及び当該データの第三者への提供
                </li>
                <li>
                  前各号の目的に付随する利用
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第６条（個人情報の第三者提供）</div>
              <div>
                当社は、収集した個人情報等を個人情報保護法に基づき第三者に対して開示または提供しないものとします。ただし、以下の各号に該当する場合は、この限りではありません。
              </div>
              <ol class="par">
                <li>
                  第三者（提携企業および業務委託会社等）に対して、前条記載の利用目的の達成に必要な範囲内において、個人情報の取扱いの全部または一部を開示または提供する場合
                </li>
                <li>法令により開示が認められている場合</li>
                <li>法令により開示を求められた場合</li>
                <li>
                  人の生命、身体または財産の保護のために何時用がある場合であって、本人の同意を得ることが困難である場合
                </li>
                <li>
                  警察、検察、裁判所、消費者センター等公的機関等によって開示を求められた場合
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第７条（個人情報の管理）</div>
              <ol>
                <li>
                  当社は、個人情報保護法に基づき個人情報等を厳重に管理し機密保持に努めるものとします。ただし、当社は、情報の漏洩、消失、他者による改ざん等が完全に防止されることについてのユーザーに対する保証を一切行わないものとします。
                </li>
                <li>
                  当社は、前条第１号により第三者に個人情報を提供する場合は、当該第三者について厳正な調査を行い、取扱いを委託された個人情報の安全管理が図られるよう当該第三者に対する必要かつ適切な監督を行います。
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第８条（個人情報に関する安全管理措置）</div>
              <div>個人情報への不正アクセス、個人情報の漏えい、滅失、又は毀損の予防及び是正のため、当社内において規程を整備し安全対策に努めます。以上の目的を達するため、当社は以下の措置を講じています。</div>
              <ol>
                <li>
                  個人情報の適正な取扱いのため、法令及びガイドライン所定が定める各対応を実施するに当たっての基本方針の策定
                </li>
                <li>
                  取得・利用・保存・提供・削除・廃棄等の各対応及び責任者と役割を定めた各種規定の策定
                </li>
                <li>
                  責任者の設置、個人情報を取り扱う従業員及び取扱い個人情報の範囲の明確化、法及び規程に違反している事実又は兆候を把握した場合の責任者への報告連絡体制の整備、取扱い状況に関する定期点検等の組織的安全管理措置
                </li>
                <li>
                  個人情報についての秘密保持に関する事項を就業規則に記載し、個人データの取扱いに関する留意事項について研修を実施する等の人的安全管理措置
                </li>
                <li>
                  従業員の入退出管理、持ち込み機器の制限、個人情報を取り扱う機器及び電子媒体・書類の盗難・紛失等を防止するための持ち出し制限・管理等の物理的安全管理措置
                </li>
                <li>
                  個人情報を取り扱う情報システムに対する外部からの不正アクセス又は不正ソフトウェアから保護するシステムの導入等の技術的安全管理措置
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第９条（ユーザーによる照会）</div>
              <div>
                当社は、ユーザーより当該個人情報の照会・訂正・追加・削除・利用の停止または消去を求められた場合、本人確認を行うものとします。なお、当社はユーザー本人による照会等であると当社が判断した場合、合理的な期間内に照会等に対応するものとします。
              </div>
            </div>

            <div class="item">
              <div class="item_title">第10条（Cookieその他の技術の利用）</div>
              <div>
                本サービスは、Cookieおよびこれに類する技術を利用することがあります。これらの技術は、当社による本サービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。ただし、Cookieを無効化すると、当社のサービスの一部の機能をご利用いただけなくなる場合があります。なお、かかる技術の使用により、ユーザーが入力していない個人情報を取得することはありません。
              </div>
            </div>

            <div class="item">
              <div class="item_title">第11条（プライバシーポリシーの変更）</div>
              <div>
                本プライバシーポリシーの変更は、当社が変更後のプライバシーポリシーを本サービス上に掲載した時点で効力を生じるものとします。
              </div>
            </div>

            <div class="item">
              <div class="item_title">第12条（免責）</div>
              <div>
                当社は、第三者によるユーザーの個人情報の取得があった場合でも、以下に該当する場合、一切責任を負いません。
              </div>
              <ol class="par">
                <li>ユーザーが本サービスの機能または別の手段を用いて第三者に個人情報を明らかにした場合</li>
                <li>ユーザーが同第三者の個人情報の取得について追認した場合</li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第13条（本ポリシーの変更）</div>
              <ol class="par">
                <li>当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、随時本ポリシーを変更することがあります。</li>
                <li>変更後の本ポリシーについては、本サービス上又は当社の運営するウェブサイトでの掲示その他分かりやすい方法により告知します。ただし、法令上ユーザーの同意が必要となるような内容の変更を行うときは、別途当社が定める方法により、ユーザーの同意を取得します。</li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第14条（管理責任者）</div>
              <div>
                当社における個人情報の管理責任者は、以下の者とします。個人情報に関するお問い合わせ先も同様とします。
              </div>
              <div>
                <br />
                事業者名：株式会社ハイスピードボーイ<br />
                運営責任者：染谷 宗宏<br />
                所在地：東京都渋谷区上原2-28-3リバーワン代々木上原201<br />
                電話番号：03-6407-0790<br />
                E-Mail：info@sutekinasekai.net<br />
                受付時間：平日10時～17時<br />
                <br />
                制定2023年9月20日<br />
              </div>
            </div>
          </div>
        </div>
      </section>

      <AppFooter fixed theme="yellow"></AppFooter>
    </form>
  </article>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
export default {
  name: "Privacy",
  components: { AppHeader, AppFooter },
};
</script>

<style scoped>
ol.par li {
  list-style-type: none;
  counter-increment: cnt;
}

ol.par li::before {
  content: counter(cnt) ")";
  display: inline-block;
  margin-left: -1.5em; /* サイトに合せて調整 */
  width: 1.5em; /* サイトに合せて調整 */
}
</style>
